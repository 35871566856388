export default {
  $vuetify: {
    back: '返回上一级',
    backTop: '回到顶部',
    home: '首页',
    detail: '更多详情',
    noData: '目前暂无数据',
    noPdf: '目前暂无规格书。',
    noAttachment: '目前暂无附件。',
    downloadAttachment: '附件下载',
    publishTime: '发布时间：',
    updatedTime: '更新时间：',
    views: '浏览量：',
    follow: '关注我们',
    links: '友情链接',
    address: '广东省东莞市松山湖园区工业北四路5号工业大厦606室',
    product: {
      name: '产品',
      title: '产品展示',
      seriesMore: '更多系列产品',
      more: '更多产品系列',
      pdf: '规格书',
      article: '相关文章',
      attachment: '相关附件'
    },
    case: {
      title: '应用案例',
      more: '更多案例',
    },
    support: {
      title: '技术支持',
      more: '更多内容',
      watch: '前往查看'
    },
    news: {
      title: '企业资讯',
      more: '更多资讯'
    },
    about: {
      title: '关于我们',
      more: '更多详情'
    },
    friend: {
      title: '友情链接',
    },
    search: {
      none: '暂无搜索结果',
      result: '搜索结果',
      history: '近期搜索历史记录',
      lookingFor: '您在找什么？',
    },

  },
}