<template>
  <router-view/>
</template>

<script>

export default {

}

</script>

<style lang="scss">

.background-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.background-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

video {
  width: 100% !important;
}

iframe {
  width: 100% !important;
}

</style>