export default {
  serverIp: process.env.VUE_APP_BASE_URL,
  systemConfig: {
    siteNameZh: process.env.VUE_APP_NAME,
    siteNameEn: process.env.VUE_APP_NAME_EN
  },
  modelList: {
    about: {
      value: 1,
      path: '/about/',
      nameZh: '关于',
      nameEn: 'About'
    }, news: {
      value: 5,
      path: '/news/',
      nameZh: '企业资讯',
      nameEn: 'News'
    }, product: {
      value: 6,
      path: '/product/',
      nameZh: '产品详情',
      nameEn: 'Product Detail'
    }, productSeries: {
      value: 7,
      path: '/product/series/',
      nameZh: '产品系列',
      nameEn: 'Product Series'
    }, support: {
      value: 9,
      path: '/support/',
      nameZh: '技术支持',
      nameEn: 'Supports'
    }, supportSeries: {
      value: 10,
      path: '/support/series/',
      nameZh: '技术支持分类',
      nameEn: 'Supports Category'
    }, useCase: {
      value: 11,
      path: '/case/',
      nameZh: '应用案例',
      nameEn: 'Use Cases'
    }
  }
}